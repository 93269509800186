*
  outline: none

.bg-pay
  background-color: #fafafa

.wrapper
  //filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 0.27))
  //-webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 0.27))
  //border: 1px solid $gray-5
  filter: drop-shadow(0px 0px 1px #a7aaad)
  -webkit-filter: drop-shadow(0px 0px 1px #a7aaad)
  border-radius: 4px
  max-width: 680px
  margin: 0 auto
  &__head
    width: 100%
    min-height: 50px
    background-color: #fff
    display: flex
    align-items: center
    justify-content: space-between
    padding: 13px 10px 14px 20px
    border-top: none
    margin-bottom: 12px
    border-radius: 0
    &-frame
      height: 30px
      margin: 0 auto
      border-radius: 4px
      width: auto
      padding: 0 7px 0 0
  &__body
    padding: 13px
    margin: 0 auto 11px
    &-frame
      padding: 4px 20px 20px
      min-height: 395px
      margin: 0 auto
      overflow-y: hidden
    &-check
      padding: 13px
      margin: 11px 0 22px

.header
  &-your,
  &-your-frame
    font-size: 11px
    text-align: right
    line-height: 1
  &-your
    padding-right: 7px
    &-frame
      padding-right: adaptiveSize(7, 18, 320, 680)
  &-bonus
    font-size: 20px
    font-weight: 500
    padding-right: 5px
    &-custom-theme
      padding-right: 16px
    &-img
      margin-right: 9px
      &-frame
        @include size(16, 13)
        margin-right: adaptiveSize(4, 18, 320, 680)

  &-icon
    transition: .5s
    &:hover
      color: #000

.terminal
  &-info
    &-urdata
      padding-bottom: 5px
    &-name
      padding-bottom: 6px
    &-name,
    &-desc
      word-break: break-word
  &-pay
    &-to-pay
      padding-bottom: 4px


.enter-sum
  font-size: 14px
  color: $default

.card-short
  display: grid
  grid-template-columns: 58px 86px 72px 1fr
  &-pan
    padding-right: 15px
  &-cvc
    padding-right: 15px

.new-card
  &-container
    max-width: $card-width
    border-radius: 7px
    //height: 35px
    padding: 5px 8px
    transition: .2s
    border: 1px solid #dee2e6
    &:hover,
    &:focus,
    &.hover,
    &.focus
      outline: none
  &-fields
    // height: 112px
    padding: 0 15px
    display: grid
    grid-template-areas: 'new-card-number new-card-number' 'new-card-cardholder new-card-cardholder' 'new-card-exp new-card-cvc'
    border-radius: 7px
    //box-shadow: 0 0 20px 0px rgba(62, 85, 105, .25)
  &-img
    @include size(40, 16)
    background-repeat: no-repeat
    background-size: auto 100%
    background-position-x: right
    grid-area: new-card-img
    justify-self: end
  &-number
    grid-area: new-card-number
    padding-bottom: 12px
  &-cardholder
    grid-area: new-card-cardholder
    padding-bottom: 12px
  &-exp
    grid-area: new-card-exp
  &-cvc
    grid-area: new-card-cvc
    justify-self: end
  &-save
    max-width: $card-width

.block-cards
  position: relative
  padding: 10px 0
  &-loading
    display: flex
    position: absolute
    width: 100%
    height: calc(100% + 30px)
    z-index: 1
    background: #fff
    font-size: 20px
    opacity: .85

.pay
  &-frame
    &-company-name
      line-height: $lh-3
      padding-bottom: 6px
    &-sum
      display: flex
      align-items: center
      padding-bottom: 14px
      line-height: $lh-1
    &-for-pay
      padding-bottom: 4px
      padding-bottom: 12px
      &-title
        padding-bottom: 8px
        font-size: 14px
      &-sum,
      &-bonuses
        display: flex
        flex: 1
        line-height: 1
        align-items: center
    &-bonus
      &-get
        //background-color: #e9f3fc
        padding: 10px
        width: 100%
        margin: 0 auto
        border-radius: 10px
        margin-bottom: 10px
        max-width: 262px
      &-spent
        display: flex
        justify-content: space-between
        line-height: $lh-1
        width: 218px
        &-container
          display: flex
          flex-direction: column
          align-items: center
          margin: 0 auto
    &-divider-1
      @include size(1, 13)
      background-color: $gray-2
      margin: 0 13px
    &-get-check
      max-width: 262px
  &-steps
    display: flex
    padding-top: 5px
  &-first-step
    display: flex
    flex-direction: column
    width: 100%
    margin: 0 auto
    max-width: 262px
    &-top
      padding: 0 16px
      max-width: 262px
  &-second-step
    display: flex
    flex-direction: column
    &-cards
      margin: 0 auto
      max-width: 295px
      &.desktop
       max-width: 262px



.iframe-divider
  @include size(2, 300)
  background-color: #f3f3f3

@media (min-width: 380px)
  .wrapper
    &__head
      border-radius: 0 0 5px 5px
      &-frame
        border-radius: 4px


@media (min-width: 704px)
  .header
    &-your-frame
      padding-right: 18px
    &-bonus-img-frame
      margin-right: 18px

  .pay
    &-second-step
      width: calc(50% - 1px)
      padding-left: 20px
    &-first-step
      &-add
        width: calc(50% - 1px)
        max-width: none
        padding-right: 20px
      &-top
        padding: 0
        margin: 0
        &.desktop
          max-width: none


    &-frame
      &-bonus-get
        margin: 0 0 10px 0



