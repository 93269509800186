// Colors
$primary: #78c2fe
$default: #3e5569


$gray-1: #e2e2e2
$gray-2: #9e9e9e
$gray-4: #999
$gray-5: #dee2e6

$danger: #ff5050

$color-1: #2196f3

// PaymentPages
$card-width: 327px

// Line height
$lh-1: 1
$lh-3: 1.2

// for iframe
$frame-width-max: 680px


// CSS variables
html
  --primary: #78c2fe
  --default: #3e5569
  --gray-5: #dee2e6

  --shadow-primary: 0 0 5px 0px #8ccaff
  --shadow-default: 0 0 5px 0px #3e556950

