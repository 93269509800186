.img
  &-loader
    background-size: 200px auto
    background: url("../img/logo.svg") no-repeat center
  &-company
    @include size(50, 50)
    border: 1px solid #dee2e6
    margin-right: 10px
    border-radius: 50%
    background-repeat: no-repeat
    background-size: calc(100% - 10px)
    background-position: center
    background-clip: padding-box
    grid-area: t-info-img
  &-tips-terminal
    @include size(75, 75)
    border: 1px solid #dee2e6
    margin-right: 10px
    border-radius: 4px
    background-repeat: no-repeat
    background-size: contain
    background-position: center
    background-clip: padding-box
    grid-area: t-info-img
  &-header
    @include size(105, 30)
    background: url("../img/logo.svg") center/100% no-repeat
    &-frame
      @include size(96, 20)



.times-top,
.times-bottom
  position: absolute
  left: 50%
  transform: translateX(-50%)


.times-top
  bottom: 5px


.times-bottom
  top: 5px
