@include placeholder('input-cash')
  font-size: 32px
  font-weight: 500
  letter-spacing: normal

@include placeholder('form-input-1')
  font-size: 14px
  letter-spacing: normal

@include placeholder('textarea-comment')
  letter-spacing: normal

